import { useContext } from "react";
import { AppContext } from "../context";

function useTitle() {
  const { setTitle, title } = useContext(AppContext);

  return {
    setTitle,
    title
  };
}

export default useTitle;
