import { GA_ACTIONS, GA_CATEGORIES, GA_LABELS } from "../constants/gaConstants";

export const SELECT_PUBLICATION_EVENT = (pubName) =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.SELECT_PUBLICATION,
    label: `${pubName}`
  });

export const LOGIN_EVENT = () =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.LOGIN,
    label: GA_LABELS.LOGIN_PRIVATE
  });

export const CREATE_ACCOUNT_EVENT = () =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.CREATE_ACCOUNT,
    label: GA_LABELS.CREATE_ACCOUNT_PRIVATE
  });

export const SELECT_PACKAGE_EVENT = (packageName) =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.SELECT_PACKAGE,
    label: `${packageName}`
  });

export const SELECT_TEMPLATE_EVENT = (templateName) =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.SELECT_TEMPLATE,
    label: `${templateName}`
  });

export const COMPLETE_UPGRADE_PUBLICATION_EVENT = () =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.COMPLETE_UPGRADE_PUBLICATION,
    label: `Upgrade Publication Complete`
  });

export const ADD_SOCIAL_MEDIA_EVENT = () =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.ADD_SOCIAL_MEDIA,
    label: `Add Social Media`
  });

export const ADD_SOCIAL_MEDIA_SETTINGS_EVENT = () =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.ADD_SOCIAL_MEDIA_SETTINGS,
    label: `Add Social Media Settings`
  });

export const BEGIN_AD_BUILD_EVENT = (templateName) =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.BEGIN_AD_BUILD,
    label: `${templateName}`
  });

export const COMPLETE_AD_BUILD_EVENT = (templateName) =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.COMPLETE_AD_BUILD,
    label: `${templateName}`
  });

export const SELECT_SCHEDULE_EVENT = () =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.SELECT_PUBLICATION_DATES,
    label: `Publish Dates Selected`
  });

export const PLACE_ORDER_EVENT = (publication, upackage, template, amount) =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.PLACE_ORDER,
    label: `${publication.name} ${upackage.name} ${template.name}`,
    attributes: {
      amount: `${amount}`
    }
  });

export const ORDER_COMPLETE_EVENT = () =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.PLACE_ORDER,
    label: `Order Complete`
  });

export const PLACE_ORDER_TWO_FOR_THREE_EVENT = (publication, upackage, template, amount) =>
  Object.freeze({
    category: GA_CATEGORIES.PRIVATE_PARTY,
    action: GA_ACTIONS.PLACE_ORDER_TWO_FOR_THREE,
    label: `${publication.name} ${upackage.name} ${template.name}`,
    attributes: {
      amount: `${amount}`
    }
  });
