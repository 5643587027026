import { useState } from "react";
import { useQuery } from "@apollo/client";
import request from "../graphql/serverRequest";

function usePortal({ portalId: id }) {
  const [portal, setPortal] = useState(null);

  useQuery(request.Portal, {
    variables: { id },
    onCompleted: (data) => {
      setPortal(data.Portal);
    }
  });

  return portal;
}

export default usePortal;
