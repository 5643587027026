import PropTypes from "prop-types";
import ReactSelect from "react-select";
import styles from "../../../scss/components/general/ErrorableSelect.module.scss";

const customStyles = {
  option: (provided, { data }) => ({
    ...provided,
    fontSize: "16px",
    fontWeight: "500",
    color: "#898989",
    backgroundColor: data.id % 2 !== 0 ? "#ffffff" : "rgba(45, 45, 45, 0.1)",
    cursor: "pointer"
    // "&:hover": {
    //   backgroundColor: "#000000",
    //   color: "#ffffff",
    // },
  }),
  control: (provided, state) => ({
    ...provided,
    marginTop: "10px",
    borderRadius: "0",
    cursor: "pointer",
    padding: "0 0",
    boxShadow: 0,
    "&:hover": {
      border: "solid 2px rgba(45, 45, 45, 0.75)"
    },
    ...(state.selectProps?.errors ? { border: "2px solid tomato" } : { border: "solid 2px #898989" })
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#898989",
    fontWeight: "500",
    textTransform: "capitalize"
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display: "none"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none"
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "rgba(0, 68, 137, 0.1)",
    border: "solid 1px #004489",
    borderRadius: "0"
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#004489"
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#004489",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#004489"
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontZize: "16px",
    fontWeight: "500"
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none"
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0",
    border: "solid 2px rgba(45, 45, 45, 0.75)",
    backgroundColor: "#f4f4f4",
    marginTop: "-2px",
    zIndex: "999"
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0"
  }),
  input: (provided) => ({
    ...provided,
    height: "45px",
    margin: "-5px",
    padding: "10px 0 0"
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "3px 0 3px 8px"
  })
};

const Select = ({ list, placeholder, handleSelect, isMulti, value, label, error, errorText, required, name }) => (
  <div className={styles.container}>
    {label && <span>{required ? `${label} *` : label}</span>}
    <ReactSelect
      value={value?.value && value}
      styles={customStyles}
      options={list}
      errors={error}
      name={name}
      placeholder={<div>{placeholder}</div>}
      onChange={handleSelect}
      isMulti={isMulti}
    />
    {error && <span className={styles.error}>{errorText}</span>}
  </div>
);

Select.defaultProps = {
  isMulti: false,
  error: false,
  required: false,
  value: "",
  name: "",
  errorText: "",
  label: "",
  placeholder: "Placeholder",
  handleSelect: () => {},
  handleBlur: () => {},
  list: []
};

Select.propTypes = {
  isMulti: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ value: PropTypes.string })]),
  errorText: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  handleSelect: PropTypes.func,
  handleBlur: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string }))
  ])
};

export default Select;
