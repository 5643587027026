/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
// <!-- Global site tag (gtag.js) - Google Analytics -->

// <script async src="https://www.googletagmanager.com/gtag/js?id=UA-201489721-1"></script>

// <script>

// window.dataLayer = window.dataLayer || [];

// function gtag(){dataLayer.push(arguments);}

// gtag('js', new Date());

// gtag('config', 'UA-201489721-1');

// </script>

export const AnalyticsTags = () => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TLFP4R');`
        }}
      />
    </>
  );
};

export function fireEvent({ action, category, label, attributes = {} }) {
  if (typeof window !== "undefined" && window.gtag) {
    gtag("event", action, {
      event_category: category,
      event_label: label,
      ...attributes
    });
  }
}
