import { useContext, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Link from "next/link";
import { v4 as uuidv4 } from "uuid";
import { withTranslation } from "next-i18next";

import { OrderDataContext } from "../context";

import { fireEvent } from "../analytics/fireEvents";
import { SELECT_PUBLICATION_EVENT } from "../analytics/privatePartyAnaltyicsEvents";

import Banner from "components/UI/Banner";
import CardNewspapers from "components/general/CardNewspapers";
import Select from "components/general/Select";
import Button from "components/UI/Button";

import Wrapper from "../layouts/Wrapper";

import styles from "scss/components/homePage/Home.module.scss";
import ContentStyles from "scss/components/general/ListGroupNewspapers.module.scss";

import useTitle from "hooks/useTitle";
import usePortal from "hooks/usePortal";
import usePublications from "hooks/usePublications";
import useClearData from "hooks/useClearData";
import useOverlay from "hooks/useOverlay";
import useMasterOrder from "../redux/hooks/useMasterOrder";

import logger from "utils/loggerFrontEnd";
import { tracker, trackEvent } from "utils/snowplow";

const noop = () => {};

const Home = ({ t, selectItem, authStatus, portalId }) => {
  const [selectedPublication, setSelectedPublication] = useState(null);

  const publications = usePublications();

  const portal = usePortal({ portalId });

  const createPub = useCreatePub({ publications, portal, selectItem, authStatus, portalId });

  const { setTitle } = useTitle();

  // TODO Update the setTitle API so we can call it without the useEffect
  // bake the useEffect into the setTitle hook
  useEffect(() => {
    setTitle("Home");
  }, []);

  const bannerImage = t(`homepage_hero_${portalId}`, t("homepage_hero"));

  return (
    <div className={styles.container}>
      <Banner
        image={bannerImage}
        header="Obituary Notices"
        text={portal?.homePageBannerText}
        styleBanner="homeBanner"
      />
      <Wrapper styleWrapper="wrapperHome">
        <div className={ContentStyles.container}>
          <h3 className={ContentStyles.header}>{`Place An Obituary`}</h3>
          <p className={ContentStyles.info}>
            {portal?.homePageWelcomeText}
            <br />
            <br />
            {`If you do not have an account, please create one `}
            <Link href="/create-account">
              <a>here</a>
            </Link>
            .
          </p>

          <ol>
            <li>Scroll down on page to choose desired newspaper.</li>
            <li>Select desired notice template.</li>
            <li>Customize your notice by uploading photographs and adding text.</li>
            <li>Proofread your notice carefully.</li>
            <li>
              Select the date(s) you wish the notice to publish. When you purchase two days, a third day is included at
              no additional charge.
            </li>
            <li>Complete your transaction by paying by credit card.</li>
          </ol>

          {portal?.homepageDesign === "ALT_2" && (
            <div
              style={{
                marginBottom: "100px"
              }}
            >
              <p
                style={{
                  marginTop: "20px"
                }}
                className={ContentStyles.info}
              >
                Select a newspaper for your notice to get started.
              </p>
              <div className={ContentStyles.selectWrapper}>
                <div
                  style={{
                    width: "500px"
                  }}
                >
                  <Select
                    handleSelect={setSelectedPublication}
                    placeholder={"Select a Newspaper"}
                    name="publication"
                    list={publications.map((pub) => ({
                      label: pub.name,
                      value: pub.id
                    }))}
                    label={""}
                    value={selectedPublication}
                    textStyle="marginTextCreate"
                    required={false}
                    error={false}
                    errorText={""}
                    handleBlur={noop}
                  />
                </div>
                <div className={ContentStyles.btnWrapper}>
                  {selectedPublication && (
                    <Button
                      disabled={!selectedPublication}
                      color={"buttonCardMobile"}
                      onClick={createPub(selectedPublication?.value)}
                      id={selectedPublication?.value}
                    >
                      Start
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}

          {portal?.homepageDesign === "ORIGINAL" && (
            <div className={true ? ContentStyles.containerCards : ContentStyles.containerCardsGallery}>
              {publications.length > 0 &&
                publications.map((item, index) => (
                  <CardNewspapers
                    key={[index, item.id].join("_")}
                    id={item.id}
                    header={item.name}
                    img={item.logo}
                    pricing={item?.packages[0]?.pricing?.basePrice}
                    info={item.description}
                    btnName={t("startHere")}
                    createPub={createPub}
                    hideBtn={false}
                    listView={true}
                    handleGroup={noop}
                    checkCard={undefined}
                    publicationId={item.id}
                  />
                ))}
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

Home.defaultProps = {
  dataPortal: {},
  selectItem: () => {},
  authStatus: false,
  portalId: ""
};

Home.propTypes = {
  t: PropTypes.func.isRequired,
  dataPortal: PropTypes.shape({
    portalID: PropTypes.string
  }),
  selectItem: PropTypes.func,
  authStatus: PropTypes.bool,
  portalId: PropTypes.string
};

export const getServerSideProps = async () => {
  return {
    props: {
      namespacesRequired: ["common", "indexPage"]
    }
  };
};

export default withTranslation("indexPage")(Home);

const useCreatePub = ({ publications, portal, authStatus, portalId }) => {
  const router = useRouter();
  const { orderData } = useContext(OrderDataContext);

  const { updateMasterOrder } = useMasterOrder();
  const clearData = useClearData();

  const { setOverlay, setOverlayTitle, clearOverlay } = useOverlay();

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return useCallback(
    (id) => () => {
      if (!portal.id) {
        // This should never happen
        // but if it does we should alert user and stop the process
        alert("There was an error choosing this publication. Please reload the page and try again.");
        return;
      }

      setOverlay(true);
      setOverlayTitle("Starting your notice...");

      (async function () {
        await clearData();

        if (!authStatus) {
          setOverlayTitle("Logging in...");

          await delay(500);

          router.push("/login").then(clearOverlay);

          return;
        }

        const selectedPub = publications.find((pub) => pub?.id === id);

        if (!selectedPub) {
          await logger.warn(`selectedPub not found`, {
            publications,
            id
          });

          return;
        }

        const orderId = uuidv4();

        await logger.info(`Creating new order with id: ${orderId}`, {
          orderId
        });

        updateMasterOrder(
          {
            portal,
            selectedPublications: [selectedPub],
            id: orderId
          },
          true
        );

        fireEvent(SELECT_PUBLICATION_EVENT(selectedPub.name));

        tracker.trackEvent("ORDER_FLOW", "SELECT_PUBLICATION", selectedPub.name);

        router.push("/create-publication/choose-package").then(clearOverlay);
      })();
    },

    [portal, publications, orderData, portalId]
  );
};
