const GA_CATEGORIES = {
  PRIVATE_PARTY: "Private Party",
  PLACE_ORDER: "Place Order"
};

const GA_ACTIONS = {
  SELECT_PUBLICATION: "Select Publication",
  LOGIN: "Log In",
  CREATE_ACCOUNT: "Create Account",
  SELECT_PACKAGE: "Select Package",
  SELECT_TEMPLATE: "Select Template",
  BEGIN_AD_BUILD: "Begin Ad Build",
  COMPLETE_AD_BUILD: "Complete Ad Build",
  SELECT_PUBLICATION_DATES: "Select Publication Dates",
  SELECT_SCHEDULE_TWO_FOR_THREE: "Select Publication Dates Two For Three",
  PLACE_ORDER: "Placed Order",
  PLACE_ORDER_TWO_FOR_THREE: "Placed Order Two For Three",
  COMPLETE_UPGRADE_PUBLICATION: "Complete Upgrade Publication",
  ADD_SOCIAL_MEDIA: "Add Social Media",
  ADD_SOCIAL_MEDIA_SETTINGS: "Add Social Media Settings"
};

const GA_LABELS = {
  LOGIN_PRIVATE: "Log In Private Party",
  CREATE_ACCOUNT_PRIVATE: "Create Account Private Party"
};

export { GA_CATEGORIES, GA_ACTIONS, GA_LABELS };
