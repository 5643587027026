import { useState } from "react";
import { useQuery } from "@apollo/client";
import request from "../graphql/serverRequest";
import useProfile from "./useProfile";
import useDataPortal from "././useDataPortal";

const usePublications = () => {
  const profile = useProfile();
  const dataPortal = useDataPortal();

  const [publications, setPublications] = useState([]);

  const input = {
    limit: 20,
    portalId: dataPortal?.portalID,
    userType: profile?.userType
  };

  useQuery(request.Publications, {
    variables: { input },
    // There is a bug with pricing
    // The publications are fetched before you log in
    // when you log in, the pricing for restricted packages isn't updated
    // turning off the cache for now
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setPublications([...data.Publications.publications]);
    },
    onError: (error) => {
      console.log(error);
    }
  });

  return publications;
};

export default usePublications;
